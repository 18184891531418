import {React, useCallback} from "react";
import Particles from "react-particles";
import ParticleData from "./Particles/particles.json";
import { loadSlim } from "tsparticles-slim"; 

import './App.css';



function App() {
  
  const particlesInit = useCallback(async engine => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    await loadSlim(engine);
  }, []);


  const particlesLoaded = useCallback(async container => {
    await console.log(container);
  }, []);


  return (
    <>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={ParticleData}
      />  

      <div className="min-h-screen">
          <div className="min-h-screen flex flex-col justify-center items-center space-y-2">
            <h1 className="font-500 text-center text-white text-4xl tracking-wider drop-shadow-sm shadow-black select-none">KEGLEZ</h1>
            <p className="font-400 text-center text-white text-lg tracking-wider drop-shaddow-sm shaddow-black select-none">Coming soon...</p>
          </div>                  
      </div>
    </>
  );
}


export default App;
